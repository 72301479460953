body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@media (prefers-color-scheme: dark) {
  body {
    background-color: black;
    color: white;
  }
}

@font-face {
  font-family: FletchaLM;
  src: url(./assets/fonts/FletchaBronzea/FlechaBronzeaL-Medium.woff2);
}

@font-face {
  font-family: FletchaMM;
  src: url(./assets/fonts/FletchaBronzea/FlechaBronzeaM-Medium.woff2);
}

@font-face {
  font-family: FletchaMB;
  src: url(./assets/fonts/FletchaBronzea/FlechaBronzeaM-Bold.woff2);
}

.react-toast-notifications__toast__content {
  flex: 1;
  justify-content: flex-start !important;
}

.react-toast-notifications__toast__icon-wrapper, .react-toast-notifications__toast__content, .react-toast-notifications__toast__dismiss-button {
  align-content: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.react-toast-notifications__toast__icon-wrapper {
  width: 30px;
}

.react-toast-notifications__toast__dismiss-button {

}

*::-webkit-scrollbar {
    display: none;
}

* {
    scrollbar-width: 0;
}
